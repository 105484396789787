import axios from 'axios'
export function ApiClient() {
  return axios.create({
    baseURL: '/',
    headers: {
      'Content-type': 'application/json',
      'key': 'DEFAULT',
      "authentication": localStorage.getItem("authentication") ?? "",
      "domain": localStorage.getItem("platformId") ?? ""
    },
  })
} 
export function ApiClientSelectedPlatform(platformId) {
  return axios.create({
    baseURL: '/',
    headers: {
      'Content-type': 'application/json',
      'key': 'DEFAULT',
      "authentication": localStorage.getItem("authentication") ?? "",
      "domain": platformId
    },
  })
} 